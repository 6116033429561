import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import {
  Button, Col, Container, Form, Row,
} from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

import { SubscriptionType, ThematicPages } from 'consts';
import { useSubscription } from 'contexts';
import { Checkbox } from 'view/base';

import './Newsletter.scss';

const messageMap = {
  'validation.error.not-unique': 'Ezzel az e-mail címmel már fel vagy iratkozva!',
};

const getError = (error) => {
  const message = error && error.messages && error.messages[0];
  const name = message && message.name;
  return (messageMap[name]) || `A feliratkozás rendszerporbléma miatt jelenleg nem működik (${name || 'ismeretlen kód'})!`;
};

const Newsletter = ({ newsletter }) => {
  const [values, setValues] = useState({
    name: '',
    email: '',
    privacyStatementAccept: false,
    dataHandlingAgreement: false,
  });

  const {
    subscribeByEmail,
    error,
    loading,
    response,
  } = useSubscription();
  const success = response?.status === 200;
  const onChange = useCallback((fieldName) => (event) => setValues((prevValues) => ({ ...prevValues, [fieldName]: event.target.value })), []);

  const onSubscriptionSubmit = useCallback((ev) => {
    ev.stopPropagation();
    ev.preventDefault();
    subscribeByEmail({
      type: SubscriptionType.NEWSLETTER,
      newsletter,
      ...values,
    });
  }, [subscribeByEmail, values, newsletter]);

  const onPrivateStatementAcceptClick = useCallback((event, privacyStatementAccept) => {
    setValues((prevValues) => ({ ...prevValues, privacyStatementAccept }));
  }, []);

  const onDataHandlingAgreementClick = useCallback((event, dataHandlingAgreement) => {
    setValues((prevValues) => ({ ...prevValues, dataHandlingAgreement }));
  }, []);
  const { privacyStatementAccept, dataHandlingAgreement } = values;
  const submitDisabled = loading || !privacyStatementAccept || !dataHandlingAgreement;

  return (
    <Container fluid id="hirlevel" className="newsletter-container">
      <Form onSubmit={onSubscriptionSubmit}>
        <Row className="pt-3">
          <Col sx={12} sm={3}>
            <div className="newsletter-title">FELIRATKOZÁS HÍRLEVÉLRE</div>
          </Col>
          <Col sx={12} sm={3}>
            <Form.Group className="mb-3">
              <Form.Control className="newsletter-input" type="text" placeholder="NÉV" value={values.name} onChange={onChange('name')} disabled={loading} />
            </Form.Group>
          </Col>
          <Col sx={12} sm={3}>
            <Form.Group className="mb-3">
              <Form.Control className="newsletter-input" type="email" placeholder="EMAIL CÍM" name="email" value={values.email} onChange={onChange('email')} disabled={loading} />
            </Form.Group>
          </Col>
          <Col sx={12} sm={2}>
            <Button
              variant="primary"
              type="submit"
              className="newsletter-button"
              disabled={submitDisabled}
            >KÜLDÉS
            </Button>
          </Col>
          {success && (
            <Col sx={12} sm={12}>
              <div className="message success">Sikeres hírlevél feliratkozás!</div>
            </Col>
          )}
          {error && (
            <Col sx={12} sm={12}>
              <div className="message error">{getError(error && error.response && error.response.data)}</div>
            </Col>
          )}
        </Row>
        <Row className="newsletter-description">
          <Col sx={12} sm={5}>
            <div className="pt-2 pb-3">
              <b>
                Amennyiben feliratkozik hírlevelünkre, hetente válogatást küldünk Önnek a legnépszerűbb cikkeinkből.
              </b>
            </div>
            <div className="pt-2 pb-1">
              <Checkbox
                id="privacyStatementAccept"
                checked={privacyStatementAccept}
                onChange={onPrivateStatementAcceptClick}
                label={<>Megismertem az <NavLink className="privacy-link" to={ThematicPages.PrivacyInfo.path}>adatvédelmi tájékoztatót</NavLink></>}
              />
            </div>
            <div className="pt-2 pb-2">
              <Checkbox
                id="dataHandlingAgreement"
                checked={dataHandlingAgreement}
                onChange={onDataHandlingAgreementClick}
                label="Hozzájárulok, hogy az Adatkezelő hírlevél küldés céljából kezelje az adataimat"
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

Newsletter.propTypes = {
  newsletter: PropTypes.bool,

};

Newsletter.defaultProps = {
  newsletter: true,
};

export default Newsletter;
